.box{
  position : relative;

  &--video{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    @include breakpoints(sm){
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }


  &--content{
    display: flex;
    flex-direction: column;
    @include breakpoints(sm){
      flex-direction: row;
    }
  }

  &--title{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    max-width: 384px;
    .btn--transparent{
      width: 81px;
    }

  }

  &--card-title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @include breakpoints(sm){
      flex-direction: row;
      align-items: center;

    }

    .section-title{
      margin-bottom: 8px;
      @include breakpoints(sm){
        margin-bottom: 0;
      }
    }

  }

  &--links{
    .section-title{
      margin-bottom: 16px;
    }
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

  }

  &--text-section{

  }

  &--links-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include breakpoints(sm){
      flex-direction: row;
    }

    .box{
      width: 100%;
      @include breakpoints(sm){
        width: 50%;
      }
    }
  }

  &--star-line{
    display: flex;
  }
}
