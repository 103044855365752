.banner{
  max-height: 400px;
  border-radius: 16px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 18px;
  @include breakpoints(sm){
    margin-bottom: 26px;
  }
}
