.badge{
  position: absolute;
  display: flex;

  &--text{
    left: 16px;
    top: 16px;
    width: 40px;
    height: 52px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
    color: $white;
    background: url("img/badge.svg") no-repeat center center;
  }
  &--play{
    width: 40px;
    height: 40px;
    left: 16px;
    bottom: 16px;
    background: url("img/play-btn.svg") no-repeat center center;
  }
}
