 /*

$xsm: 520px !default;
$sm: 768px !default;
$md: 992px !default;
$l: 1200px !default;
*/

@mixin breakpoints($media, $dem: 'min-width') {
	@if ($media == xs) {
		@media only screen and ($dem: $xsm) {
			@content;
		}
	} @else if ($media == s) {
		@media only screen and ($dem: $sm) {
			@content;
		}
	} @else if $media == sm {
		@media only screen and ($dem: $sm) {
			@content;
		}
	} @else if $media == m {
		@media only screen and ($dem: $md) {
			@content;
		}
	} @else if $media == md {
		@media only screen and ($dem: $md) {
			@content;
		}
	} @else if $media == l {
		@media only screen and ($dem: $l) {
			@content;
		}
	} @else if $media == xl {
		@media only screen and ($dem: $xl) {
			@content;
		}
	} @else {
		@media only screen and ($dem: $media) {
			@content;
		}
	}
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
	-o-object-fit: $fit;
	object-fit: $fit;
	@if $position {
		-o-object-position: $position;
		object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}
}
