.note{
  font-size: 12px;
  line-height: 16px;
  margin: 14px 0 0 0;

  &--footer-note{
    font-weight: 400;
    line-height: 18px;
    opacity: 1;
    color: $dark;
    span{
      font-weight: 500;
    }
  }
  &--nos-partenaires{

    strong{
      font-weight: 500;

    }

    span{
      color: $red;
      font-weight: 400;
    }
  }
  &--copyright{
    margin: 28px 0 0 0;
    opacity: 0.45;
  }
}
