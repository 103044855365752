$link-font-color: black !default;
$link-font-color-hover: blue !default;

$link-font-color: $red;
$link-font-color-hover: $red;

$text-color: black !default;
$text-color: $dark;

$c-max-width: 1232px;

*{
  box-sizing: border-box;
}

html{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: $text-color;
  font-style: normal;
  font-weight: normal;

  @include breakpoints(sm){
    font-size: 14px;
    line-height: 18px;
  }

}
html, body{
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #F5F6FC;
}

img{
  width: 100%;
  height: auto;
  max-width: 100%;
}

.page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

}
main {
  flex: 1 0 auto;
  @include breakpoints(sm){
    margin: 0 32px 0 0;
  }
}

header{
  height: 54px;
  background-color: #EFF2FD;
  position: fixed;
  z-index: 9;
  width: 100vw;
  backdrop-filter: blur(10px);
  padding: 0 $scroll-padding;
  @include breakpoints(sm){
    position: relative;
    height: 64px;
    background-color:  #1E2439;
    backdrop-filter: none;
  }
  &:after{
    display: block;
    position: absolute;
    content: '';
    width: 100vw;
    top : 0;
    left: 0;
    height : 54px;
    z-index: -1;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06), 0px 8px 16px rgba(0, 0, 0, 0.16);
    @include breakpoints(sm){
      display: none;
    }
  }
}

footer {
  flex-shrink: 0;
  padding: 14px $scroll-padding 16px $scroll-padding;
  background-color: $lite-blue;
  @include breakpoints(sm){
    padding: 40px $scroll-padding;
  }
}

a {
  font-size: 14px;
  color: $link-font-color;
  text-decoration: none;
  line-height: 1;

  &:hover{
    color: $link-font-color-hover;
  }
}

.content-container{
  margin: 0 auto;
  max-width: $c-max-width;
  height: 100%;

  &--main-content{
    display: flex;
    flex-direction: column;
    padding: 24px $scroll-padding 80px $scroll-padding;
    margin-top: 54px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @include breakpoints(sm){
      padding: 32px  $scroll-padding 80px $scroll-padding;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 0;
      flex-direction: row;
    }
    @include breakpoints(1262px){
      padding: 32px 0 80px 0;
    }

    main{
      width: 100%;
      @include breakpoints(sm){
        width: 66.23%;
        max-width: 816px;
      }
    }

    aside{
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include breakpoints(sm){
        width: 31.16%;
        max-width: 384px;
      }
    }
  }
}

