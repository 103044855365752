.video-block {
  position      : relative;
  width         : 100%;
  max-width     : 260px;
  border-radius : 16px;
  height        : 176px;
  margin : 0 auto 16px auto;
  @include breakpoints(sm) {
    width  : 33%;
    margin : 0 16px 0 0;
  }

  &:last-child {
    margin : 0;
    @include breakpoints(sm) {
      margin : 0;
    }
  }
}
