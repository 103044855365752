// Breakpoints
$xsm: 520px !default;
$sm: 768px !default;
$md: 992px !default;
$l: 1200px !default;
$xl: 1440px !default;

// Colors

$dark: #0B1228;
$white: #FFFFFF;
$red: #E61938;
$dark-red: #B81830;
$lite-blue: #EFF2FD;

$scroll-padding: 15px !default;



