
.text{
  opacity: 0.75;
  line-height: 18px;
  @include breakpoints(sm){
    opacity: 0.9;
  }

  &--red{
    color: $red;
    line-height: 24px;
  }

}

.section-title{
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;


  &--card-sm{
    font-size: 20px;
    line-height: 32px;
    text-transform: none;
    letter-spacing: 1px;
  }
  &--card-md{
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
  }

  &--card-lg{
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    letter-spacing: 1px;
  }

  &--xl{
    font-size: 24px;
    line-height: 32px;
    text-transform: none;
    letter-spacing: 1px;

    @include breakpoints(sm){
      font-size: 32px;
      line-height: 40px;
    }
  }


  &--small{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
  }
}


.sub-title{
  opacity: 0.5;
  font-weight: 500;
  line-height: 16px;

  &--small{
    font-size: 12px;
  }
}
