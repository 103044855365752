.btn{
  display: block;
  position: relative;
  transition: 0.3s;
}

.btn-transparent{
  position: relative;
  width: 110px;

  &--lg{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    width: 120px;
  }

  &--sm{
    width: 81px;
  }
  &:after{
    position: absolute;
    display: block;
    content: '';
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    background: url("img/arrow-right.svg") no-repeat center center;
    background-size: contain;
  }
}

.btn-primary{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: $red;
  color : #F5F6FC;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: 0.3s;

  &--lg{
    height: 48px;
    width: 176px;
    padding-left: 22px;

    img{
      margin-left: 16px;
    }
  }

  &--md{
    width: 136px;
    height: 40px;
    padding-left: 14px;
    font-size: 12px;

    img{
      margin-left: 8px;
    }
  }

  img{
    width: 8px;
    height: 10px;
  }

  &:hover{
    box-shadow: 2px 4px 16px rgba(230, 25, 56, 0.45), 0px 0px 8px rgba(230, 25, 56, 0.2);
    color : #F5F6FC;
  }

  &:active{
    background: $dark-red;
    box-shadow: 0px 4px 16px rgba(230, 25, 56, 0.25);
  }
}

.btn-badge{
  text-transform: uppercase;
  font-size: 11px;
  align-items: center;
  letter-spacing: 1px;
  border: 1px solid $red;
  box-sizing: border-box;
  border-radius: 4px;
  padding : 6px;
  color: $dark;
  @include breakpoints(sm){
    margin: 0 0 0 16px;
  }


}

.btn-badge-tv{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color : $white;
  letter-spacing: 1px;
  border-radius: 4px;
  background-color: $red;
  width: 79px;
  height: 28px;

  &:hover{
    box-shadow: 2px 4px 16px rgba(230, 25, 56, 0.45), 0px 0px 8px rgba(230, 25, 56, 0.2);
    color : #F5F6FC;
  }

  &:active{
    background: $dark-red;
    box-shadow: 0px 4px 16px rgba(230, 25, 56, 0.25);
  }
}

.btn-round{
  width: 56px;
  height: 56px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFF2FD;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06), 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 50%;

  &:after, &:before{
    position: absolute;
    display: block;
    content: '';
    left: 50%;
    top : 50%;
    width: 8px;
    height: 14px;
    transform: translate(-50%, -50%);
  }

  &:hover{
    background: #F5F6FC;
  }

  &--active-right:after{
    background-image: url("img/arrow-right-red.svg");
  }

  &--active-left:after{
    background-image: url("img/arrow-left-red.svg");
  }
  &--disabled-right:after{
    background-image: url("img/arrow-left-gray.svg");
    transform: rotate(180deg);
  }

  &--disabled-left:after{
    background-image: url("img/arrow-left-gray.svg");
  }
  &--disabled-right:hover, &--disabled-left:hover{
    background-color: #EFF2FD;
  }



}
