.pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-round--disabled-left, .btn-round--active-left{
    margin-right: 24px;
  }
  .btn-round--disabled-right, .btn-round--active-right{
    margin-left: 8px;
  }

  &__inner{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  &__text-bock{
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    margin-right: 16px;

    &--active{
      color: $red;
    }
  }
}
