.navigation {
  display        : flex;
  flex-direction : row;

  @include breakpoints(sm) {
    flex-direction  : row;
    justify-content : space-between;
    align-items     : flex-end;
  }

  &__toggle {
    width    : 20px;
    height   : 20px;
    position : relative;
    margin: 0 5px 0 0;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      position   : absolute;
      top        : 0;
      left       : 0;
      transition : all 0.2s ease 0s;
      display: none;

      &.show {
        display: block;
      }
    }
  }

  &--header {
    height          : 100%;
    justify-content : space-between;
    align-items     : center;
    @include breakpoints(sm) {

    }
  }

  &--footer {
    flex-direction  : column;
    justify-content : flex-start;
    align-items     : flex-start;
    padding         : 0 0 24px;
    border-bottom   : 1px solid rgba(30, 36, 57, 0.25);
    flex-wrap       : wrap;
    @include breakpoints(sm) {
      flex-direction  : row;
      justify-content : space-between;
      align-items     : center;
    }
  }

  &__links {
    display   : flex;
    flex-wrap : wrap;
    @include breakpoints(sm) {
      flex-wrap : nowrap;
      padding   : 0;
    }

    &--header {
      position         : absolute;
      flex-direction   : column;
      align-items      : flex-end;
      flex-wrap        : wrap;
      right            : 0;
      top              : 54px;
      width            : 79.76vw;
      height           : calc(100vh - 56px);
      padding          : 0 48px 0 0;
      background-color : #F5F6FC;
      transform        : translateX(100vw);
      z-index: -1;
      transition       : transform 0.4s ease-in 0.1s;
      @include breakpoints(sm) {
        position         : relative;
        flex-direction   : row;
        transform        : translateX(0);
        width            : initial;
        height           : auto;
        background-color : transparent;
        top              : 0;
        left             : 0;
        padding: 0;
        right            : unset;
        transition       : transform 0s linear 0s;
        z-index: 9;
      }
    }

    &--header-show {
      transform : translateX(0);
    }
  }

  &__link {
    display : block;
    &--header {
      position       : relative;
      display        : block;
      letter-spacing : 1px;
      color          : $dark;
      font-size      : 20px;
      width          : 100%;
      margin         : 72px 0 0 0;
      text-align     : right;
      z-index        : 9;
      font-weight: 300;
      @include breakpoints(sm) {
        font-weight : 500;
        font-size   : inherit;
        margin      : 0 0 0 64px;
        color       : $white;
        width       : initial;
        padding     : 0;
      }

      &:after, &:before {
        @include breakpoints(sm) {
          display          : block;
          content          : '';
          position         : absolute;
          height           : 1px;
          border-radius    : 25%;
          background-color : $white;
          right            : 0;
          opacity          : 0;
          transition       : all 0.2s ease-in-out 0s;
        }
      }

      &:after {
        @include breakpoints(sm) {
          width            : 24px;
          background-color : $white;
          top              : 18px;
        }
      }

      &:before {
        @include breakpoints(sm) {
          width            : 16px;
          background-color : $red;
          top              : 22px;
        }
      }

      &:hover {
        font-weight : 500;
      }

      &:hover:after, &:hover:before {
        @include breakpoints(sm) {
          opacity : 1;
        }

      }
    }
    &--footer {
      font-weight    : 300;
      color          : $text-color;
      margin         : 24px 24px 0 0;
      letter-spacing : 1px;
      @include breakpoints(sm) {
        margin : 0 0 0 24px;
      }

      &:first-child {
        @include breakpoints(sm) {
          margin : 0;
        }
      }
    }

    .logo {
      width : 100%;
      @include breakpoints(sm) {
        max-width : 154px;
      }

      &--header-desktop {
        display : none;
        @include breakpoints(sm) {
          display : block;
        }
      }
    }

  }
}

.mob-shadow{
  position         : absolute;
  z-index: 1;
  flex-direction   : column;
  align-items      : flex-end;
  flex-wrap        : wrap;
  right            : 0;
  top              : 54px;
  width            : 79.76vw;
  height           : calc(100vh - 55px);
  background-color : transparent;
  transform        : translateX(100vw);
  transition       : transform 0.4s ease-in 0.1s;
  box-shadow: 0px 4px 108px #000000;
  @include breakpoints(sm){
    display: none;
  }
  &.navigation__links--header-show{
    transform        : translateX(0);
  }
}
