.d {
  &-mobile {
    &-show{
      display: initial;
      @include breakpoints(sm){
        display: none;
      }
    }
  }
  &-desktop{
    &-show{
      display: none;
      @include breakpoints(sm){
        display: block;
      }
    }
  }
}
