
.card {
  $b : &;
  display        : flex;
  flex-direction : column;
  position       : relative;
  padding        : 8px 16px 8px 8px;
  border-radius  : 20px;
  background     : $lite-blue;
  box-shadow     : 0px 0px 40px rgba(3, 6, 30, 0.07), 0px 8px 10px rgba(0, 0, 0, 0.03);
  @include breakpoints(sm) {
    flex-direction : row;
  }

  &--half {
    width : calc(50% - 8px);

    #{$b}__body {
      padding : 4px 0 8px 0;
      margin  : 0 0 0 16px;
    }

  }

  &__header {
    border-radius : 16px;

  }

  &__body {
    display         : flex;
    flex-direction  : column;
    justify-content : flex-start;
    align-items     : flex-start;
    margin          : 16px 0 0 0;
    padding         : 16px 0;
    @include breakpoints(sm) {
      padding : 16px 0;
      margin  : 0 0 0 24px;
    }
  }

  &__subtitle {

  }

  &--mini {
    width          : 100%;
    flex-direction : row;
    border-radius  : 20px;
    height         : 120px;
    max-width      : 384px;
    margin         : 0 0 8px 0;

    p {
      padding : 0;
      margin  : 0 0 4px 0;
    }

    .btn {
      margin-top : 5px;
    }

    #{$b}__body {
      margin : 0 0 0 16px;
      padding     : 8px 0;
      @include breakpoints(sm){
        margin-left : 16px;
      }

    }
  }

  &--large {
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : flex-start;
    max-width: 345px;
    margin: 0 0 16px 0;
    @include breakpoints(sm) {
      flex-direction : row;
      align-items    : stretch;
      max-width: none;
    }

    #{$b}__header {
      display: flex;
      width : 100%;
      border-radius: 8px;
      @include breakpoints(sm) {
        width     : 47.3%;
        max-width : 368px;
        max-height: 256px;
      }
    }
    #{$b}__body {
      width : 100%;
      padding: 0 8px 8px 8px;
      @include breakpoints(sm) {
        padding: 16px 0;
        width     : 62%;
        max-width : 392px;
      }

      .section-title{
        flex-grow: 1;
      }

      .text{
        display: none;
        font-size: 14px;
        line-height: 20px;
        opacity: 0.75;
        @include breakpoints(sm) {
          display: initial;
        }
        flex-grow: 1;
      }
    }
  }
}

.cards{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @include breakpoints(sm){
    flex-wrap: nowrap;
  }
}
