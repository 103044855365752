
.apple-ico{
  width: 14px;
  height: 13px;
  opacity: 0.45;
  margin-left: 10px;
}

.star-ico{
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 8px;

  &--on{
    background-image: url("img/star-on.svg");
  }
  &--off{
    background-image: url("img/star-off.svg");
  }

}
