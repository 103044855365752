.h-margin {

  &--last-child-bottom-#{0} {

    & > *:last-child {
      margin-bottom : 0;
    }
  }

  &--top {
    &-auto {
      margin-top : auto;
    }
    &-#{16} {
      margin-top : 16px;
    }

    &-#{20} {
      margin-top : 20px;
    }

    &-#{30} {
      margin-top : 30px;
    }

    &-#{48} {
      margin-top : 48px;
    }

    &-#{40} {
      margin-top : 40px;
    }

    &-#{60} {
      margin-top : 60px;
    }
  }

  &--right {

    &-#{12pct} {
      margin-right : 12%;
    }
  }

  &--bottom {

    &-#{8} {
      margin-bottom : 8px;
    }
    &-#{10} {
      margin-bottom : 10px;
    }

    &-#{16} {
      margin-bottom : 16px;
    }
    &-#{32} {
      margin-bottom : 32px;
    }

    &-#{20} {
      margin-bottom : 20px;
    }

    &-#{30} {
      margin-bottom : 30px;
    }

    &-#{40} {
      margin-bottom : 40px;
    }

    &-#{60} {
      margin-bottom : 60px;
    }
  }
}
